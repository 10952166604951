
/*=============================================
=            section title            =
=============================================*/

.section-title{
    display: inline-block;
    font-size: 40px;
    color: #001b2c;
    font-weight: 700;
    margin-bottom: 11px;
    font-family: 'HelveticaNeue-Bold', sans-serif;

    @media #{$extra-small-mobile}{
        font-size: 20pt;
    }
}

.section-subtitle{
    font-size: 18px;
    line-height: 1.5;
    color: #1d1d25;
    font-family: $poppins;
}

.title-icon{
    width: 4px;
    height: 20px;
    background-color: $theme-color--default;
    display: block;
    margin: 0 auto;
    margin-top: 15px;
}

/*=====  End of section title  ======*/

